@font-face {
  font-family: 'Lexend';
  font-display: block;
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('lexend-200.woff2') format('woff2'),
       url('lexend-200.woff') format('woff'),
       url('lexend-200.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend';
  font-display: block;
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('lexend-300.woff2') format('woff2'),
       url('lexend-300.woff') format('woff'),
       url('lexend-300.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend';
  font-display: block;
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('lexend-400.woff2') format('woff2'),
       url('lexend-400.woff') format('woff'),
       url('lexend-400.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend';
  font-display: block;
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('lexend-500.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend';
  font-display: block;
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('lexend-600.woff2') format('woff2'),
       url('lexend-600.woff') format('woff'),
       url('lexend-600.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend';
  font-weight: 700;
  src: local(''), url('lexend-700.ttf') format('truetype');
}
