.menuDropdown {
  box-shadow: 0px 20px 45px 0px rgba(0, 0, 0, 0.05);
}
.navItem {
  color: #23262f;
}
.navItem span {
  color: #23262f;
}
.navItem span span {
  color: #aab0c0;
}
.navItem:hover {
  color: #f4610e !important;
}
.navItem:hover span {
  color: #f4610e !important;
}
.navItem:hover span span {
  color: #f4610e !important;
}
.navItemActive {
  color: #f4610e !important;
}
.navItemActive span {
  color: #f4610e !important;
}
.navItemActive span span {
  color: #f4610e !important;
}
.aiNavItem {
  color: white;
}
.aiNavItem span {
  color: white;
}
.aiNavItem span span {
  color: #aab0c0;
}
.aiNavItemActive {
  color: #3898ec !important;
}
.aiNavItemActive span {
  color: #3898ec !important;
}
.aiNavItemActive span span {
  color: #3898ec !important;
}
